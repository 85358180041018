import React from "react"
import "./accordion.scss"

class Accordion extends React.Component {
  render() {
    const { id, question, answer } = this.props

    return (
      <div class="tab">
        <input type="checkbox" id={id} className="hidden" />
        <label class="tab-label" for={id}>
          {question}
        </label>
        <div class="tab-content" dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    )
  }
}

export default Accordion
